

















import { Component } from 'vue-property-decorator';

import Field from '@/shared/resources/components/forms/fields/Field.vue';

const TIMEOUT_TIME: number = 5; // in seconds

@Component
export default class PasswordField extends Field {
  /**
   * Data
   */
  private visible: boolean = false;
  private timeout: any | null = null;

  /**
   * Getters
   */
  private get type(): string {
    return this.visible ? 'text' : 'password';
  }

  private get icon(): string {
    return this.visible ? 'visibility_off' : 'visibility';
  }

  /**
   * Methods
   */
  private toggleVisible() {
    this.visible = !this.visible;

    if (this.visible) {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.visible = false;
      }, TIMEOUT_TIME * 1000);
    }
  }

  /**
   * Handlers
   */
  private onAppendIconClick() {
    this.toggleVisible();
  }
}
